import React from 'react';
import {connect, useDispatch} from "react-redux"
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/UkResidents/HCP/footer';
import Header from '../../../components/UkResidents/HCP/header';
import Alkindi from "../../../images/alkindi_cmyk.png"
import Pdf from "../../../images/pdf_icon.png"
import UkImg from "../../../images/uk_flag.png"
import German from "../../../images/german.png"
import Czech from "../../../images/czech-flag.png" 
import { Icon } from 'react-icons-kit'
import {ic_picture_as_pdf} from 'react-icons-kit/md/ic_picture_as_pdf'
import {link} from 'react-icons-kit/icomoon/link'
import { Link, navigate, graphql } from "gatsby";
import PdfFile from "../../../images/uk_prescribing-information_version-4-pp.pdf"
import ScrollAnimation from 'react-animate-on-scroll';
import {showUkResidentsHcpDisclaimerPopup} from "../../../actions/ukResidents/hcp"
import {getUkResidentsHcpUKPatientsDisclaimerPopupConfigs} from "../../../util/configs"
import {deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions, capitalizeFirstLetter} from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultUkResidentsHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import queryString from "query-string"
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryCountryInformationTemplateUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCountryInformationTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                bannerImage {
                    file {
                        url
                    }
                }
                bodyText {
                    json
                }
                spaceId
                slug
                title
                parentMenu {
                    country_information_template {
                      parentMenu {
                        title
                        slug
                      }
                    }
                }
                video_pdf_template {
                    title
                    flag {
                      file {
                        url
                      }
                    }
                    slug
                }
        } 
    }
`

const CountryInformationTemplatePageUkHcp = (props) => {
    const dispatch = useDispatch()

    const {bannerImage, title, spaceId, bodyText, parentMenu, video_pdf_template} = props.data.contentfulCountryInformationTemplate;

    const togglePopup = (path, patients) => {
        let okCallback = async () => await navigate(path)
        let closeCallback = async () => {}
        let popupConfig = {}
        switch(patients){
            default:
                navigate(path);
                return;
        }
        dispatch(showUkResidentsHcpDisclaimerPopup(popupConfig))
    }

    let richText = documentToReactComponents(isNullUndefined(bodyText) ? bodyText : bodyText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richText)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showUkResidentsHcpDisclaimerPopup(actionConfig));
    }
    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
        
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);
    return ( 
       <TemplateWrapper>
             <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | UK Residents | HCP`} />
            
          
                  <div className={styles.banner} style={{background: `url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`}}>
                            
                        <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                    <h1 className="">
                                       
                                       {title}</h1>
                                
                                    <ol className="custom_breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/UkResidents/HCP/home`}>Home</Link></li>
                                    {!isNullUndefined(parentMenu) && !isNullUndefined(parentMenu.country_information_template) && <li className="breadcrumb-item"><a href="#">
                                   {parentMenu.country_information_template[0].parentMenu.title}    
                                         </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                                   </div>
                                   </div>
                            </div> 
                </div>
                <div className={styles.content}>
                    <div className="container">
                    <div className="row">
                        <div className={`col-12 ${styles.prescribing_information}`}>                  
                            <ScrollAnimation animateIn="fadeIn">
                            {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                            </ScrollAnimation>
                        </div> 
                    
                   </div>
                    </div>
                </div>
                <div className={styles.flags_section}>
                   <div className="container">
                 <div className="row">
                 {video_pdf_template?.map((item, index) => {
                      return (
                          
                         <div key={index} className="col-12 col-sm-12 col-md-4">
                        <ScrollAnimation animateIn="fadeIn">
                            <div className={styles.content_section}>
                                <div className={styles.flag}>
                                <img src={!isNullUndefined(item.flag) && item.flag.file.url}/>
                                </div>
                                <h3>{item.title}</h3>
                            {/* <a onClick={() => togglePopup(`/UkResidents/HCP/${item.slug}`, item.country)}><button className="custom_btn dark bordered_btn">Read more</button></a>  */}
                            <Link to={`/UkResidents/HCP/${item.slug}`}>
                            <button className="custom_btn dark bordered_btn">Read more</button>
                            </Link>
                        </div>
                        </ScrollAnimation>
                        </div>
                        )
                    })}
               
                   
                   
                    </div>
                   </div>
                </div>
                <Footer/>
       </TemplateWrapper>
     );
}

export default CountryInformationTemplatePageUkHcp;